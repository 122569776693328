/* eslint-disable no-console */
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("@/views/AboutView.vue"),
  },
  {
    path: "/destination/:page",
    name: "Destination",
    component: () => import("@/views/DestinationView.vue"),
    props: (route) => ({ page: route.params.page }),
  },
  {
    path: "/blogs",
    name: "Blogs",
    component: () => import("@/views/BlogsView.vue"),
  },
  {
    path: "/blogs/:id",
    name: "Blog",
    component: () => import("@/views/BlogView.vue"),
    props: (route) => ({ id: route.params.id }),
  },
  {
    path: "/faq",
    name: "Faq",
    component: () => import("@/views/FAQView.vue"),
  },
  {
    path: "/tripplan",
    name: "TripPlan",
    component: () => import("@/views/TripPlanView.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("@/views/ContactView.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/loginView.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/registerView.vue"),
  },
  {
    path: "/user/:id",
    name: "User",
    component: () => import("@/views/UserView.vue"),
    props: (route) => ({ id: route.params.id }),
  },
  {
    path: "/licenses",
    name: "Licenses",
    component: () => import("@/views/LegalView.vue"),
    props: () => ({ page: "licenses" }),
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("@/views/LegalView.vue"),
    props: () => ({ page: "privacy" }),
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("@/views/LegalView.vue"),
    props: () => ({ page: "terms" }),
  },
  {
    path: "/unsubscribe",
    name: "Unsubscribe",
    component: () => import("../views/UnsubscribeView.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/views/404View.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

// router.afterEach((to, from, failure) => {
//   console.log("to: ", to);
//   console.log("from: ", from);
//   if (isNavigationFailure(failure)) {
//     console.log("Failed Navigation: ", failure);
//   }
// });

export default router;
