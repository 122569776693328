<template>
  <router-view v-slot="{ Component }">
    <!-- <transition name="route" mode="out-in"> -->
    <component :is="Component"></component>
    <!-- </transition> -->
  </router-view>
</template>

<script setup>
import { onMounted } from "vue";
import { RouterView } from "vue-router";
import { useAuthStore } from "./store/authStore";
import { auth, onAuthStateChanged } from "./assets/js/firebase.js";

onMounted(async () => {
  const authStore = useAuthStore();
  // Update state when user auth state changes
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      // Get the user's custom claim 'role' or default to 'user'
      const idTokenResult = await user.getIdTokenResult(true);
      const role =
        idTokenResult.claims && idTokenResult.claims.role
          ? idTokenResult.claims.role
          : "user";
      user.role = role;
      authStore.setUser(user);
    } else {
      authStore.setUser(null);
    }
  });
});
</script>

<style>
.route-enter-from {
  opacity: 0;
  transform: translateY(-100px);
}

.route-enter-active {
  transition: all 0.4s ease-out;
}

.route-leave-to {
  opacity: 0;
  transform: translateX(100px);
}
</style>
