/* eslint-disable no-unused-vars */
// * https://firebase.google.com/docs/web/setup#available-libraries

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  Timestamp,
  doc,
  setDoc,
  getDocs,
  query,
  orderBy,
  limit,
  getDoc,
  where,
  startAfter,
  writeBatch,
  arrayUnion,
  updateDoc,
  deleteDoc,
  getCountFromServer,
  arrayRemove,
  serverTimestamp,
} from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  updateProfile,
  signOut,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

// * Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDuoVBZRWL-FsLVLLcq9_GDUdo1VhFGbEg",
  authDomain: "nectar-and-gold-safaris.firebaseapp.com",
  projectId: "nectar-and-gold-safaris",
  storageBucket: "nectar-and-gold-safaris.appspot.com",
  messagingSenderId: "213339384354",
  appId: "1:213339384354:web:ec58091fd1a148a855787b",
  measurementId: "G-JLK69GPGV8",
};

// * Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const firestore = getFirestore(app);
const storage = getStorage();
const auth = getAuth();
auth.useDeviceLanguage();
export { auth, onAuthStateChanged };

// * Add message to firestore
export async function sendMessage(name, email, phone, message) {
  try {
    const messagesCollection = collection(firestore, "Messages");

    await addDoc(messagesCollection, {
      name,
      email,
      phone,
      message,
      status: "unread",
      date: Timestamp.now(),
    });

    return { success: true };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error sending message:", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}
// * Add trip plan to firestore
export async function planYourTrip(
  full_name,
  email,
  phone,
  residence,
  budget,
  more_info
) {
  try {
    const tripPlanCollection = collection(firestore, "TripPlan");

    await addDoc(tripPlanCollection, {
      full_name,
      email,
      phone,
      residence,
      budget,
      more_info,
      status: "unread",
      date: Timestamp.now(),
    });

    return { success: true };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error adding trip plan:", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Function to subscribe an email
export async function subscribe(email) {
  try {
    // * Reference to the "Email" collection
    const emailsCollection = collection(firestore, "Subscribers");

    const queryRef = query(
      emailsCollection,
      where("email", "==", email),
      limit(1)
    );
    const querySnapshot = await getDocs(queryRef);
    if (querySnapshot.docs.length === 0) {
      // * Add the email to the collection
      await addDoc(emailsCollection, { email });
      return { success: true };
    } else {
      return { success: false, error: "You are already subscribed" };
    }
  } catch (error) {
    console.error("Error subscribing email:", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Function to unsubscribe an email
export async function callUnsubscribe(email) {
  try {
    const unsubscribe = httpsCallable(functions, "unsubscribe");
    const result = await unsubscribe({ email });
    return { ...result.data };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Function to signin with email & password
export async function signInWithEP(email, password) {
  return signInWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      const idTokenResult = await userCredential.user.getIdTokenResult(true);
      console.log(idTokenResult.claims.role);
      const role =
        idTokenResult.claims && idTokenResult.claims.role
          ? idTokenResult.claims.role
          : "user";
      return { success: true, role };
    })
    .catch((error) => {
      console.error("Error signing in :", error);
      return { success: false, error: error.message || "An error occurred" };
    });
}

// *Function to create newaccount with email & password
export async function newAccountWithEP(name, email, password) {
  await createUserWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      await updateProfile(userCredential.user, {
        displayName: name,
      }).then(async () => {
        const user = userCredential.user;
        const uid = user.uid;
        const displayName = user.displayName;
        const userEmail = user.email;

        const documentRef = doc(firestore, "Users", uid);
        try {
          await setDoc(documentRef, {
            email: userEmail,
            displayName,
            photoURL: null,
            description: null,
            links: [],
            role: "user",
            dateJoined: Timestamp.now(),
          });
          return { success: true };
        } catch (error) {
          console.error("Error creating user doc:", error.message);
          return {
            success: false,
            error: error.message || "Error creating user doc:",
          };
        }
      });
    })
    .catch((error) => {
      console.error("Error creating user account:", error);
      return { success: false, error: error.message || "An error occurred" };
    });
  return { success: true };
}

// * Function to signout
export async function signout() {
  return signOut(auth)
    .then(() => {
      return { success: true };
    })
    .catch((error) => {
      return { success: false, error: error.message || "An error occurred" };
    });
}

// * get user doc
export async function getUserDoc(uid) {
  try {
    const userDocRef = doc(firestore, "Users", uid);
    const userDocSnapshot = await getDoc(userDocRef);
    if (userDocSnapshot.exists()) {
      return { success: true, user: { id: uid, ...userDocSnapshot.data() } };
    } else {
      return { success: false, error: "User not found" };
    }
  } catch (error) {
    console.error("Error getting user doc:", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Update user
export async function updateUser(user) {
  try {
    if (user.photoURL && user.photoURL instanceof Blob) {
      const photoStorageRef = ref(storage, `displayPicture/${user.id}`);
      await uploadBytes(photoStorageRef, user.photoURL);
      user.photoURL = await getDownloadURL(photoStorageRef);
    }
    const functionCall = httpsCallable(functions, "updateUser");
    const result = await functionCall({
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL,
      description: user.description,
      links: user.links,
    });
    return { ...result.data };
  } catch (error) {
    console.log(error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Get all the articles docs
export async function getAllArticles(articlesLimit, lastDoc, tag) {
  try {
    const articles = [];
    let queryRef = collection(firestore, "Articles");

    if (tag && tag !== "all") {
      queryRef = query(queryRef, where("tag", "==", tag));
    }
    queryRef = query(queryRef, orderBy("timestamp", "desc"));

    if (lastDoc) {
      queryRef = query(queryRef, startAfter(lastDoc));
    }
    queryRef = query(queryRef, limit(articlesLimit || 20));

    const querySnapshot = await getDocs(queryRef);
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    querySnapshot.forEach((doc) => {
      articles.push({ ...doc.data(), id: doc.id });
    });
    return { success: true, articles, lastDoc: lastVisible };
  } catch (error) {
    console.error("Error getting articles :", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Get user the articles docs
export async function getUserArticles(articlesLimit, lastDoc, uid) {
  try {
    const articles = [];
    let queryRef = collection(firestore, "Articles");
    if (uid) {
      queryRef = query(queryRef, where("authorId", "==", uid));
      queryRef = query(queryRef, orderBy("timestamp", "asc"));
    } else {
      queryRef = query(queryRef, orderBy("timestamp", "asc"));
    }

    if (lastDoc) {
      queryRef = query(queryRef, startAfter(lastDoc));
    }
    queryRef = query(queryRef, limit(articlesLimit || 20));

    const querySnapshot = await getDocs(queryRef);
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    querySnapshot.forEach((doc) => {
      articles.push({ ...doc.data(), id: doc.id });
    });
    return { success: true, articles, lastDoc: lastVisible };
  } catch (error) {
    console.error("Error getting articles :", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Get Article by id
export async function getArticle(id) {
  try {
    const articleDocRef = doc(firestore, "Articles", id);
    const articleDocSnapshot = await getDoc(articleDocRef);
    if (articleDocSnapshot.exists()) {
      return {
        success: true,
        article: { id: id, ...articleDocSnapshot.data() },
      };
    } else {
      return { success: false, error: "Article not found" };
    }
  } catch (error) {
    console.error("Error getting article:", error);
    return { success: false, error: error.message || "An error occurred" };
  }
}

// * Create new Article
export async function addArticle(article) {
  const user = auth.currentUser;
  if (user) {
    try {
      var docValues = {
        title: article.title,
        image: null,
        authorName: user.displayName,
        content: article.content,
        timestamp: Timestamp.now(),
        readTime: article.readTime,
        authorId: user.uid,
        tag: article.tag,
      };
      if (article.image) {
        const imageStorageRef = ref(storage, `articleImages/${article.title}`);
        await uploadBytes(imageStorageRef, article.image);
        docValues.image = await getDownloadURL(imageStorageRef);
      }

      await addDoc(collection(firestore, "Articles"), docValues);
      return { success: true };
    } catch (error) {
      console.log(error);
      return { success: false, error: error.message || "An error occurred" };
    }
  } else {
    console.log("Login to add a new article");
    return { success: false, error: "Login to add a new article" };
  }
}

// * Update Article
export async function updateArticle(article) {
  const user = auth.currentUser;
  if (user) {
    try {
      if (article.id) {
        var docValues = {
          title: article.title,
          image: null,
          authorName: article.authorName,
          content: article.content,
          timestamp: Timestamp.now(),
          readTime: article.readTime,
          authorId: article.authorId,
          tag: article.tag,
        };
        const articleDocRef = doc(firestore, "Articles", article.id);
        if (article.image && article.image instanceof Blob) {
          const imageStorageRef = ref(
            storage,
            `articleImages/${article.title}`
          );
          await uploadBytes(imageStorageRef, article.image);
          docValues.image = await getDownloadURL(imageStorageRef);
        } else {
          docValues.image = article.image;
        }
        await updateDoc(articleDocRef, docValues);
        return { success: true };
      } else {
        console.log("No Article id provided");
        return { success: false, error: "Something went wrong" };
      }
    } catch (error) {
      console.log(error);
      return { success: false, error: error.message || "An error occurred" };
    }
  } else {
    console.log("Login to update a new article");
    return { success: false, error: "Login to update a new article" };
  }
}

// * Update Role
export async function updateRole(email, role) {
  try {
    const functionCall = httpsCallable(functions, "updateRole");
    const result = await functionCall({ email, role });
    return { ...result.data };
  } catch (error) {
    console.log(error);
    return { success: false, error: error.message || "An error occurred" };
  }
}
